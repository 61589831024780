.App {
  position: relative;
  width: 1440px;
  height: 1024px;
  
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.punkgif{
  position: relative;
  text-align: center;
  top: 20px;


  border-radius: 10%;
  justify-content: center;

}
.AppHeaderBar{
 position: relative;
 display: flex;
 flex-direction: row;
 justify-content: space-evenly;
 align-items: center;
 flex-wrap: wrap;

min-height: 83px;
left: 0px;
top: 0px; 

background: #FFFFFF;
box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.25);
}

.AppLogo{
position: relative;
left: -305px;

top: 40px;
bottom: 40.36%;
}

svg .rainbowFill {
  fill: #f01a37;
  transition: fill .5s ease;
}

.css-5n3810 {
  
  display: flex;
  appearance: none;
  text-decoration: none;
  flex-direction: row;
 justify-content: right;
  -webkit-box-pack: right;
  justify-content: center;
  align-self: center;

  white-space: nowrap;
  vertical-align: middle;
  align-items: center;
  outline-offset: 2px;
  width: auto;
  line-height: 1.2;
  
  font-weight: 400;
  height: 2.5rem;
  width: 9.2rem;
  font-size: 0.75rem;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  cursor: pointer;
  color: #FFFFFF;
  background-color: #F01a37;
  text-transform: uppercase;
  outline: transparent solid 2px;
  border-radius: 12px;
  border-width: 1px;
  border-style: solid;
  transition: all 250ms ease 0s;
  border-color: #f01a37;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.sizingv2 {
  margin-top: -1rem;
  margin-bottom: -1rem;
}

.css-5n3810v2 {
  position: relative;
  display: flex;
  appearance: none;
  min-height: 2.5rem;
  text-decoration: none;
  flex-direction: row;
 justify-content: center;
  -webkit-box-pack: center;
  justify-content: center;
  align-self: center;
  white-space: nowrap;
  vertical-align: middle;
  align-items: center;
  text-align: center;
  outline-offset: 2px;
  width: auto;
  line-height: 1.2;
  
  font-weight: 400;
  height: 2.5rem;
  width: 9.2rem;
  font-size: 0.75rem;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  cursor: pointer;
  color: #FFFFFF;
  background-color: #ffffff;
  text-transform: uppercase;
  outline: transparent solid 2px;
  border-radius: 12px;
  border-width: 1px;
  border-style: solid;
  transition: all 250ms ease 0s;
  border-color: #ffffff;
}

.css-5n3810v3 {
  position: relative;
  display: flex;
  appearance: none;
  text-decoration: none;
  flex-direction: row;
 justify-content: center;
  -webkit-box-pack: center;
  justify-content: center;
  align-self: center;
  white-space: nowrap;
  vertical-align: middle;
  align-items: center;
  text-align: center;
  outline-offset: 2px;
  width: auto;
  top: -495px;
  line-height: 1.2;
  
  font-weight: 400;
  height: 2.5rem;
  margin-top: -2.5rem;
  
  font-size: 0.75rem;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  cursor: pointer;
  color: #1b1b1b;
  background-color: #ffffff;
  text-transform: uppercase;
  outline: transparent solid 2px;
  border-radius: 12px;
  border-width: 1px;
  border-style: solid;
  transition: all 250ms ease 0s;
  border-color: #ffffff;

}

.imgholder {
  padding: 5px;
  border-radius: 10%;
}
.punkgifv2 {
  padding-bottom: 3.5rem;
  position: relative;
  text-align: center;
  top: 20px;
 


  border-radius: 10%;
  justify-content: center;
}
.css-1qqp0n5v3 {
  max-width: 500px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  margin-top: 1rem;
  padding: 1rem;
  background:linear-gradient(#f01a37, rgb(77, 13, 13));
  border-radius: 10px;
  border-color: red;
  border-width: 10px ;

}
.fontsizesmall{
  font-size: 12px ;
  color: white;
  margin-top: 5px;

}

.css-1qqp0n5v4 {
  max-width: 500px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 1rem;
  border-radius: 10px;
  border-width: 10px ;
  font-size: 12px ;
  color: white;

}
.css-n6x0i2 {
  
  position: relative;
  align-self: center;
  margin-right: 10px;
  right: 21%;
  background: rgb(255, 255, 255);
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.socialIcon {

  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}


.css-1c7st4f {
  color: #FF0420;
  font-style: italic;
  font-weight: 500;
  text-decoration: none !important;
  align-self: center;
}
.sizing {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  height: 60px;
  width: 147.19px;
  padding-left: 50px;
}
.rainbowText {
  
  background-clip: text;
  font-size: 40px;
  
  
  -webkit-background-clip: text;
}
.rainbowlogintext {
  background-clip: text;
  text-decoration: none;
  text-transform: uppercase;

  transition: all 250ms ease 0s;
  -webkit-background-clip: text;
  color: #FFFFFF;
  font-weight: 400;
  align-items: center;


}

.quantityInput__3YZli {
  background-color: transparent;
  border: 1px solid #ffffff;
  max-width: 9.2rem;
  max-height: 2.5rem;
  border-radius: 12px;
  color: #ffffff;
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
  letter-spacing: 0;
  margin: 10px ;
  padding: 13px 16px;
  text-transform: uppercase;
  width: 100%;
}

.rainbowlogintextinverse {
  background-clip: text;
  text-decoration: none;
  text-transform: uppercase;

  transition: all 250ms ease 0s;
  -webkit-background-clip: text;
  color: #FF0420;
  font-weight: 400;
  align-items: center;


}


body {
  background-image:  url("https://gateway.optimism.io/static/media/bg-grid-dark.6ce462f7.svg"),url("backgroundreal.svg")  ;
  background-size: contain;
  background-repeat: repeat;
  background-size: 1450px;
  background-origin: padding-box;
  background-position: center;
}

.rainbowlogintextmain {
  position: relative;
  background-clip: text;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  -webkit-background-clip: text;
  
  color: #ffffff;
  font-weight: 400;
  align-items: center;
  top: 50px;
  font-weight: bold;

}
.moretext {   
  
  background-clip: text !important;
  text-transform: uppercase !important;
   transition: all 0.3s ease 0s !important;
  -webkit-background-clip: text !important;
}
a {
  
  transition: all 0.3s ease 0s !important;
}

.css-pisauv {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  min-height: calc(100vh - 100px);
}

.css-1lh6tyf {
  text-align: center;
  border-radius: 10px;
  display: flex;
  flex: 1 1 0%;
}

.css-1qqp0n4 {
  max-width: 500px;
  margin: auto;
 
  padding: 1rem;
  background: rgb(255, 255, 255);
  border-radius: 10px;
  
}

.css-1qqp0n5 {
  
  max-width: 500px;
  height: 440px;
  margin: auto;
  margin-top: 1rem;
  padding: 1rem;
  background:linear-gradient(#f01a37, rgb(77, 13, 13));
  border-radius: 10px;
  border-color: red;
  border-width: 10px ;
}

.css-1qqp0n5v2 {
  
  max-width: 500px;
  height: 150px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  margin-top: 1rem;
  padding: 1rem;
  background:linear-gradient(#f01a37, rgb(77, 13, 13));
  border-radius: 10px;
  border-color: red;
  border-width: 10px ;
  
  
}
.css-15l1v17 {
  font-family: Rubik,sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.33;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  text-align: left;
}

.css-m7jr6f {
  display: flex;
  appearance: none;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: left;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline-offset: 2px;
  width: 100%;
  line-height: 1.2;
  font-weight: 400;
  height: auto;
  min-width: 2.5rem;
  font-size: 1rem;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
  background-color: #FF0420;
  cursor: pointer;
  letter-spacing: 0.2rem;
  outline: transparent solid 2px;
  border-radius: 8px;
  border-width: 1px;
  padding: 1rem;
  transition: background 300ms ease 0s;
}



.css-mxtfqv {
  position: relative;
  align-self: center;

  width: 2.5rem;
  height: 2.5rem;
}

.css-1dvms1e {
  position: relative;
  align-self: center;
  margin-top: 18px !important;
  margin-left: 15px;
  color: #FFFFFF;
 
  font-size: 1.25rem;
}

button {
  border-color: rgb(115, 115, 115);
  font-family: var(--bs-font-sans-serif);
  box-shadow: none !important;
}
.css-89fiat {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  min-height: 35px;
}

.css-1r8x5vp {
  min-height: 300px;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-direction: row;
  padding-top: 1rem;
  margin-bottom: 1rem;
}

.css-iratnv {
  display: inline-flex;
  appearance: none;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline-offset: 2px;
  width: auto;
  line-height: 1.2;
  font-weight: 600;
  transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;
  transition-duration: 200ms;
  height: 2.5rem;
  min-width: 2.5rem;
  font-size: 1rem;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  background-color: transparent;
  outline: transparent solid 2px;
  border-radius: 0.75rem;
  border: var(--chakra-borders-none);
  padding: 0px;

}

.css-ltqpi4 {
  width: 35px;
  height: 35px;
  display: inline-block;
  line-height: 1em;
  flex-shrink: 0;
  color: #ff0420;
  vertical-align: middle;
  transform: rotate(
90deg);
}
.rainbowBg, .rainbowText {
  
  transition: all .3s ease-in-out!important;
}

.AppHeaderFont {
  margin: 0;
  position: absolute;
  
  
  top: 14px;
  
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 49px;
  text-decoration: none;
  -webkit-tap-highlight-color: none;
  /* identical to box height */
  
  text-align: center;
  
  color: #222222;
}

.Loginbutton {
margin-top: 7px;
position: relative;
width: 135px;
height: 69px;
left: 310px;
background: #FF0420;
border-radius: 17px;


font-family: Montserrat;
font-style: normal;
font-weight: bold;
font-size: 18px;
line-height: 22px;
display: flex;
align-items: center;
text-align: center;

color: #000000;
}
.Acount {


position: relative;
size: auto;
width: 804px;
height: 129px;
top: 100px;
left: 0;
right: 0;
top: ;
background: #FFAD33;
border-radius: 17px;

display: flex;
align-items: center;
text-align: center;
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

color: #000000;

}

.lefttext {
position: absolute;
left: 16.42%;
right: 65.17%;
top: 18.6%;
bottom: 64.34%;

font-family: Montserrat;
font-style: normal;
font-weight: bold;
font-size: 18px;
line-height: 22px;
/* identical to box height */

display: flex;
align-items: center;
text-align: center;

color: #000000;}

.MarketBoxText {
  font-family: Montserrat;
  font-style: normal;
  text-decoration: none;
  color: #000000;
  font-size: 14px;
  
  text-align: left;
  

}
.righttext {
  position: absolute;
left: 66.29%;
right: 16.92%;
top: 18.6%;
bottom: 64.34%;
width: 185px;

font-family: Montserrat;
font-style: normal;
font-weight: bold;
font-size: 18px;
line-height: 22px;
/* identical to box height */

display: flex;
align-items: center;
text-align: center;

color: #000000;

}

.seperatingline {
position: absolute;
left: 47.280%;
right: 47.28%;
top: 50.68%;
bottom: 71.32%;

border: 0.7px solid #FFFFFF;
transform: rotate(90deg);
}

.Usdcbalance {
 position: relative;
 top: 40px;
 right: 90px;

align-items: center;

font-family: Montserrat;
font-style: normal;
font-weight: bold;
font-size: 18px;
line-height: 22px;
/* identical to box height */



color: #000000;

}
.modal {
font-family: Montserrat;
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 29px;
text-align: center;

color: #000000;
}

.cards {
position: flex;
display: grid;
grid-template-columns: repeat(3,0.1fr);
gap: 1rem;
align: center;

}
.card {
  cursor: pointer;
  background-color: transparent;
  height: 300px;
  perspective: 1000px;
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.card-img{
   object-fit: cover;
   width: 100% !important;
   height: 100px !important;
   font-size: 24px;
   object-fit: cover;
}

.container123 {
position: relative;
top: 150px;


}

a:hover {
  color: black !important;
}

.BuySell{
  position: absolute;
width: 1440px;
height: 83px;
left: 0px;
top: 0px;

background: #FFFFFF;
box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.25);
}
.marketscreentitle{
 position: relative;
width: 703px;
height: 89px;
left: 100px;
top: 114px;

}

.marketscreentexttitle{
position: absolute;
left: 2.7%;
right: 2.28%;
top: 19.1%;
bottom: 12.36%;

font-family: Montserrat;
font-style: normal;
font-weight: bold;
font-size: 18px;
line-height: 22px;
display: flex;
align-items: center;

color: #000000;
}
.marketscreenbox{
  position: absolute;
left: 0%;
right: 0%;
top: 0%;
bottom: 0%;

background: rgba(255, 173, 51, 1);
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 17px;
}

.backbuttonmaster{
position: relative;
width: 86px;
height: 86px;
right: ;
top: 30px;

}
.backbuttoncircle{
position: absolute;
left: 0%;
right: 0%;
top: 0%;
bottom: 0%;

background: rgba(255, 173, 51, 0.9);
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.backbuttonarrow{
  position: absolute;
left: 74.42%;
right: -22.09%;
top: 51.16%;
bottom: 48.84%;

border: 4px solid #000000;
box-sizing: border-box;
transform: rotate(180deg);
}

.leftinfobox{
  position: relative;
width: 359px;
height: 166px;
right: ;
top: 63px;
}

.leftrectangle{
position: absolute;
left: 0%;
right: 0%;
top: 0%;
bottom: 0%;

background: #F2F2F2;
border: 1px solid #C6C6C6;
box-sizing: border-box;
border-radius: 17px;
}



.liquidityheader{
  position: absolute;
left: 6.41%;
right: 78.83%;
top: 24.7%;
bottom: 65.66%;

font-family: Montserrat;
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 16px;

color: #000000;
}
.liquiditytext{
  position: relative;
left: ;
right: ;
top:25px ;
bottom: ;

align-items: center;
text-align: center;

font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 16px;

color: #000000;

}

.totalvolumeheader{
  position: absolute;
left: 36.49%;
right: 39.55%;
top: 24.7%;
bottom: 65.66%;

font-family: Montserrat;
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 16px;

color: #000000;
}
.totalvolumetext{
  position: absolute;
left: 44.29%;
right: 44.57%;
top: 50%;
bottom: 40.36%;

font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 16px;

color: #000000;

}

.closingdateheader{
  position: absolute;
left: 73.82%;
right: 4.18%;
top: 24.7%;
bottom: 65.66%;

font-family: Montserrat;

font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 16px;

color: #000000;
}
.closingdatetext{
   position: relative;
left: ;
right: ;
top:18px ;
bottom: ;

align-items: center;
text-align: center;

font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 16px;

color: #000000;

}

.yestext{

position: absolute;
width: 130px;
height: 30px;
left: 0px;
top: 70px;

background: #FFAD33;
border-radius: 2px;


font-family: Montserrat;
font-weight: bold;
font-size: 14px;
line-height: 12px;
display: flex;
align-items: center;
text-align: center;

color: #000000;

}

.screenseperator1 {
  position: relative;


width:50px;
left: 80px;
top: 90px;
border: 1px solid #C6C6C6;
transform: rotate(90deg);
}

.screenseperator2 {
  position: relative;


width:50px;
left: 215px;
top: 90px;
border: 1px solid #C6C6C6;
transform: rotate(90deg);
}

.rightinfobox{
 position: relative;
width: 359px;
height: 80px;
left: 445px;
top: -60px;

background: #F2F2F2;
border: 1px solid #C6C6C6;
box-sizing: border-box;
border-radius: 17px;
}

.buysellbox{
position: relative;
width: 803px;
height: 399px;
left: ;
top: 19px;

background: #F2F2F2;
border: 1px solid #C6C6C6;
box-sizing: border-box;
border-radius: 17px;
}

.marketboxseperatingline{
position: relative;
width: 803px;
height: 0px;
right: 0;
top: 56px;

border: 1px solid #C6C6C6;
transform: rotate(180deg);
}

.marketboxseperatingline2{
position: relative;
width: 803px;
height: 0px;
right: 0;
top: 190px;

border: 1px solid #C6C6C6;
transform: rotate(180deg);
}

.firstcircle{
position: relative;
width: 228px;
height: 32px;
left: 64px;
top: 229px;

border: 1px solid #C6C6C6;
box-sizing: border-box;
border-radius: 17px;
}

.secondcircle{
position: relative;
width: 228px;
height: 32px;
left: 64px;
top: 242px;

border: 1px solid #C6C6C6;
box-sizing: border-box;
border-radius: 17px;
}

.thirdcircle{
position: absolute;
width: 228px;
height: 78px;
left: 320px;
top: 233px;

border: 1px solid #C6C6C6;
box-sizing: border-box;
border-radius: 17px;
}

.executetradebox{
  position: relative;
width: 177px;
height: 50px;
left: 611px;
top: 267px;

background: rgba(255, 173, 51, 0.9);
border-radius: 17px;
border: 0.5px solid #C6C6C6;
}

.executetradeboxtext{
 position:relative;
top: 4px;
width:168px;
left: 5px;
height:42px;
font-family: Montserrat;
font-weight: bold;
font-size: 18px;
line-height: 22px;
align-items: center;
text-align: center;


color: #000000;

}

.yesnoseperator{
 position: relative;
width: 268px;
height: 0px;
left: 279px;
top: 5px;

border: 1px solid #C6C6C6;
transform: rotate(180deg);
}

.outcometext{
  position: relative;
width: 57px;
height: 16px;
left: 66px;
top: -92px;

font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 16px;

color: #000000;
}

.outcometext{
  position: relative;
width: 57px;
height: 16px;
left: 66px;
top: -92px;

font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 16px;

color: #000000;
}

.outcometext{
  position: relative;
width: 57px;
height: 16px;
left: 66px;
top: -92px;

font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 16px;

color: #000000;

}

.pricetext{
  position: relative;
width: 57px;
height: 16px;
left: 498px;
top: -124px;

font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 16px;

color: #000000;

}

.mysharestext{
  position: relative;
width: 80px;
height: 16px;
left: 697px;
top: -156px;

font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 16px;

color: #000000;

}

.yesnospacing{
position: relative;
left: 66px;
top: -117px;

font-family: Montserrat;

}


.yesprice{
   position: relative;
left: 5px;
right: ;
top:60px ;
bottom: ;

align-items: center;


font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 16px;

color: #000000;

}

.noprice{
   position: relative;
left: 5px;
right: ;
top:52px ;
bottom: ;

align-items: center;

font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 16px;

color: #000000;

}

.mysharesyes{
   position: relative;
left: ;
right: ;
top:18px ;
bottom: ;

align-items: center;
text-align: center;

font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 16px;

color: #000000;

}

.mysharesno{
   position: relative;
left: ;
right: ;
top:18px ;
bottom: ;

align-items: center;
text-align: center;

font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 16px;

color: #000000;

}

.YourUSDC{
position: relative;
width: 100px;
height: 16px;
left: 10px;
top: 7.5px;

font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 16px;

color: #000000;

}

.YourUSDCseperator{
position: relative;
width: 32px;
height: 0px;
left: 88px;
top: -18px;

border: 1px solid #C6C6C6;
transform: rotate(-90deg);
}


.Max{
position: relative;
width: 100px;
height: 16px;
left: 40px;
top: 7px;

font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 16px;

color: #1652f0;
text-decoration: underline;
cursor: pointer;

}

.Maxseperator{

  position: relative;
width: 32px;
height: 0px;
left: 88px;
top: -18px;

border: 1px solid #C6C6C6;
transform: rotate(-90deg);

}

.form{
  position: relative;
    top:-51px;
  left:104px;
  height:32px;
  background: #F2F2F2;
  border-top-right-radius: 17px; 
  border-bottom-right-radius:17px; 
  
  border: 0.1px solid #C6C6C6;
  width:125px;
    padding: .8rem  ;

  font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 16px;
}

.USDCbalance{
 position: relative;
    top:-42px;
  left:117px;
  height:32px;
  width:125px;
font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 16px;
color: #000000;

}

.Buybutton{
position: relative;
width: 100px;
height: 55px;
left: 60px;
top: 12px;
background: #C6C6C6;
border-radius: 17px;
text-align: center;
display: flex;

border: 0.5px solid #FFAD33;
cursor: pointer;


}

.Sellbutton{
position: relative;
width: 100px;
height: 55px;
left: 195px;
top: -43px;
background: #C6C6C6;
border-radius: 17px;
text-align: center;
border: 0.5px solid #FFAD33;
cursor: pointer;





display: flex;
}
.buttontext{
position: relative;
left:37px ;
right: ;
top: 20px;
bottom: ;

align-items: center;
text-align: center;

font-family: Montserrat;
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 16px;

color: #000000;



}

a:link { text-decoration: none; };


a:visited { text-decoration: none; };


a:hover { text-decoration: none; };


a:active { text-decoration: none; };